import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { navigate } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const PricePage = () => (
  <Layout>
    <SEO title="撮影料金 - スタジオ撮影" />
      <p className="text-textblack text-center text-3xl lg:text-4xl self-center pt-5">撮影料金−スタジオ撮影</p>
    <div className="container space-y-5 px-5 lg:px-0 my-8 justify-center content-center">
    <p className="text-textblack text-center">すべて税込み価格です。</p>
      <p className="text-textblack text-center">基本撮影料・アルバム代・プリント代等すべて含まれています。</p>
      <p className="text-textblack text-center">なお、お客様のご要望に合わせてご提案させていただきますので、詳しくは<Link to="/#contact">お問い合わせ</Link>ください。</p>
      <hr></hr>
      <div className="text-textblack container mx-auto px-5 max-w-screen-md space-y-3">
      <h2>1ポーズ撮影</h2>
        <h3>1ポーズ(お一人様)1枚</h3>
          <p>六切　￥11,500 / 四切　￥13,500</p>
        <h3>家族写真1枚</h3>
          <p>六切　￥12,500 / 四切　￥14,500</p>
        <h3>グループ写真(大人6名様以上)1枚</h3>
          <p>六切　￥14,500 / 四切　￥16,500</p>
      <br/>
      <h2>マルチポーズ(お一人様で撮影ポーズを変える場合です。)</h2>
        <h3>2ポーズ(お一人様の2ポーズ)2面台紙</h3>
          <p>六切　￥19,800 / 四切　￥23,800</p>
        <h3>3ポーズ(お一人様の3ポーズ)3面台紙</h3>
          <p>六切　￥28,000 / 四切　￥34,000</p>
        <h3>4ポーズ(お一人様の4ポーズ)4面台紙</h3>
          <p>六切　￥35,500 / 四切　￥43,500</p>
        <h3>2PKS(1ポーズ2キャビネポーズ)2面台紙</h3>
          <p>六切　￥24,000 / 四切　￥26,000</p>
        <h3>3PKS(2ポーズ2キャビネポーズ)3面台紙</h3>
          <p>六切　￥31,500 / 四切　￥35,500</p>
        <h3>4PKS(3ポーズ2キャビネポーズ)4面台紙</h3>
          <p>六切　￥38,000 / 四切　￥44,000</p>
      <br />
        
      <h2>マルチセット(家族写真などの組み合わせになります)</h2>
        <h3>マルチ2面（Aセット） 1ポーズ+家族写真 2面台紙</h3>
          <p>六切　￥22,000 / 四切　￥26,000</p>
        <h3>マルチ3面（Bセット） 2ポーズ+家族写真 3面台紙</h3>
          <p>六切　￥31,500 / 四切　￥37,500</p>
        <h3>マルチ3面（Cセット） 1ポーズ+1ポーズ+家族写真 3面台紙</h3>
          <p>六切　￥33,500 / 四切　￥39,500</p>
        <h3>マルチ3面（Dセット） 1ポーズ+家族写真+家族写真 3面台紙</h3>
          <p>六切　￥34,500 / 四切　￥40,500</p>
        <h3>マルチ4面（Eセット） 3ポーズ+家族写真 4面台紙</h3>
          <p>六切　￥39,800 / 四切　￥47,800</p>
        <h3>マルチ4面（Fセット） 1ポーズ+1ポーズ+2人+家族 4面台紙</h3>
          <p>六切　￥44,000 / 四切　￥52,000</p>
        
        <br />
      <h2>その他異サイズ撮影料金</h2>
        <h3>キャビネサイズ</h3>
          <p>1ポーズ  ￥9,000 / 2ポーズ  ￥16,000</p>

      </div>
    </div>
  </Layout>
)

export default PricePage